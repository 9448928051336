import { cx } from "@linaria/core";
import { FreeMode, Navigation, Swiper } from "swiper";
import { SwiperSlide } from "swiper/react";
import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import { cssIsMultilevel } from "@/components/Swiper/StyledSwiper";
import { PLACEMENT_ARROW, SwiperWrapper, VARIANT_ARROW } from "@/components/Swiper/SwiperWrapper";
import { TagsSizeVariant } from "@/components/Tags/constants";
import { StyledTags, StyledTagsSectionSlider } from "@/components/Tags/StyledTags";
import { TagCategorySliderPropsType } from "@/components/Tags/types";
import { TagType } from "@/types";
import { groupByPart } from "@/utils/common/helpers";
import { TagCategory } from "./TagCategory";
const TAG_ALL = {
  uuid: "--all-categories--",
  name: "Все категории"
};
const TAG_ALL_VALUE = TAG_ALL.uuid;
const getTag = ({
  tag,
  onSelect,
  selected
}: {
  tag: TagType;
  onSelect: (val: string | number) => void;
  selected: boolean;
}) => {
  return <TagCategory tag={tag} key={tag.id ?? tag.uuid} selected={selected} onSelect={onSelect} />;
};
const TagCategorySlider: FC<TagCategorySliderPropsType> = memo(({
  tags: tagsOut,
  onSelect,
  multilevel = 0,
  isOutside = false,
  slideToStart = false,
  withStartTag = false,
  startTag = TAG_ALL,
  defaultSelected = TAG_ALL_VALUE,
  selected = TAG_ALL_VALUE,
  slideToSelected = false,
  tagKey = "uuid",
  layoutTag = getTag,
  size = TagsSizeVariant.DEFAULT
}) => {
  const tags = useMemo(() => {
    const t = [...tagsOut];
    if (withStartTag) {
      t.unshift(startTag);
    }
    return t;
  }, [startTag, tagsOut, withStartTag]);
  const [uuidSelected, setUuidSelected] = useState<string | number>(defaultSelected);
  const [swiperInstance, setSwiperInstance] = useState<Swiper | null>(null);
  const slideToStartHandle = useCallback(() => {
    if (!swiperInstance) {
      return;
    }
    swiperInstance?.slideTo(0);
  }, [swiperInstance]);
  const onSelectHandle = useCallback((uuid: string | number) => {
    setUuidSelected(uuid);
    !!onSelect && onSelect(uuid === TAG_ALL_VALUE ? null : uuid);
  }, []);
  useEffect(() => {
    if (!uuidSelected || !slideToStart) {
      return;
    }
    slideToStartHandle();
  }, [slideToStart, slideToStartHandle, uuidSelected]);
  useEffect(() => {
    if (!uuidSelected || !slideToSelected) {
      return;
    }
    let index = tags.findIndex(t => t[tagKey] === uuidSelected);
    if (index === -1) {
      return;
    }
    index = multilevel > 1 ? Math.floor(index / multilevel) : index;
    swiperInstance?.slideTo(index);
  }, [slideToSelected, swiperInstance, tags, uuidSelected, multilevel, tagKey]);
  useEffect(() => {
    return () => {
      onSelectHandle(TAG_ALL_VALUE);
    };
  }, [onSelectHandle]);
  useEffect(() => {
    if (!!selected) {
      onSelectHandle(selected);
    }
  }, [selected]);
  const tagsElements = useMemo(() => multilevel <= 0 ? <>
            {tags.map((tag, index) => <SwiperSlide key={index}>
                {layoutTag({
        tag,
        selected: !!uuidSelected && uuidSelected === tag[tagKey],
        onSelect: onSelectHandle
      })}
              </SwiperSlide>)}
          </> : <>
            {groupByPart<TagType>(tags, multilevel).map((tapsGroup, index) => <SwiperSlide key={index}>
                {tapsGroup.map(tag => layoutTag({
        tag,
        selected: !!uuidSelected && uuidSelected === tag[tagKey],
        onSelect: onSelectHandle
      }))}
              </SwiperSlide>)}
          </>, [multilevel, tags, uuidSelected, onSelect]);
  return <StyledTagsSectionSlider isOutside={isOutside} data-size={size}>
        <StyledTags>
          <SwiperWrapper onInit={swiper => {
        setSwiperInstance(swiper);
      }} slidesPerView="auto" spaceBetween={10} modules={[FreeMode, Navigation]} freeMode resistanceRatio={0} speed={0} updateOnWindowResize withNavigation variantArrow={VARIANT_ARROW.ANGLE} placementArrow={PLACEMENT_ARROW.HORIZONTAL} className={cx(multilevel > 0 && cssIsMultilevel)} isAbsoluteNav watchSlidesProgress>
            {tagsElements}
          </SwiperWrapper>
        </StyledTags>
      </StyledTagsSectionSlider>;
});
TagCategorySlider.displayName = "TagCategorySlider";
export { TagCategorySlider };