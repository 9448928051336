import { cx } from "@linaria/core";
import { BaseHTMLAttributes, FC } from "react";
import { useRouter } from "next/router";
import { cssTag, cssTagCategory, cssTagCategorySelected, cssTagText, StyledImageTag } from "@/components/Tags/StyledTags";
import { TagType } from "@/types";
import { EntityImage } from "@/ui/EntityImage/EntityImage";
import { Link } from "@/ui/Link";
import { Typography } from "@/ui/Typography/Typography";
export type TagCategoryPropsType = Omit<BaseHTMLAttributes<HTMLDivElement>, "onSelect"> & {
  tag: TagType & {
    uuid?: string;
  };
  selected?: boolean;
  onSelect?: (uuid: string) => void;
};
const TagCategory: FC<TagCategoryPropsType> = ({
  tag: {
    name = "",
    uuid = "",
    image,
    url = ""
  },
  selected = false,
  className,
  onSelect
}) => {
  const router = useRouter();
  const Component = !!url ? Link : "div";
  return <Component className={cx(cssTag, cssTagCategory, selected && cssTagCategorySelected, className)} data-id={uuid} href={url} onClick={e => {
    e.preventDefault();
    onSelect?.(uuid);
    if (!!url) {
      void router.push(url);
    }
  }}>
      <StyledImageTag>
        <EntityImage alt="" imagePath={image} layout="fill" objectFit="cover" objectPosition="center" />
      </StyledImageTag>
      <Typography variant="span" className={cssTagText}>
        {name}
      </Typography>
    </Component>;
};
export { TagCategory };